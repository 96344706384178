
import {lazy,Suspense } from "react"
import { BrowserRouter as Router, Route,Switch} from "react-router-dom"
import "react-calendar/dist/Calendar.css"
import "./App.css"

//import Schedule from "./components/Schedule"
//import Reschedule from "./components/Reschedule"
import PrivateRoute from "./components/PrivateRoute"
import { AuthProvider,SidebarProvider } from "./context/Context"
import LoadingFallback from "./components/LoadingFallback"
import LandingPage from "./components/landingPage/LandingPage"
import LandingPageFewo from "./components/landingPage/LandingPageFewo"
import FewoLegalPage from "./components/FewoLegalData"
import SkeletonCalendar from "./components/Skeletons/SkeletonCalendar"

// import HotelTestSchedule from "./components/HotelTestSchedule"

// const HotelTestSchedule = lazy(() => import ("./components/HotelTestSchedule"))

const Schedule = lazy(() => import ("./components/Schedule"))
const Testschedule = lazy(() => import ("./components/Testschedule"))
const CalendarPickerDateOnly = lazy(() => import ("./components/CalendarPickerDateOnly"))
const RangePicker = lazy(() => import ("./components/RangePicker"))
// const HotelTestSchedule = lazy(() => import ("./components/HotelTestSchedule"))
const Reschedule = lazy(() => import ("./components/Reschedule"))
const Event = lazy(() => import ("./components/Event"))
const AppSetup = lazy(() => import ("./components/setup/AppSetup"))
const Billing = lazy(() => import ("./components/Billing"))
const Settings = lazy(() => import ("./components/Settings"))
const Home = lazy(() => import ("./components/Home"))
const Docs = lazy(() => import ("./components/Docs"))
const Logs = lazy(() => import ("./components/Logs"))
const Events = lazy(() => import ("./components/Events"))
const Support = lazy(() => import ("./components/Support"))
// const SignIn = lazy(() => import ("./components/SignIn"))
const SignInPage = lazy(() => import ("./components/SignInPage"))
// const LandingPage = lazy(() => import ("./components/landingPage/LandingPage"))
const AuthCalendar = lazy(() => import ("./components/register/AuthCalendar"))
const AddGaccountSuccess = lazy(() => import ("./components/register/AddGaccountSuccess"))
const SetupRestaurant = lazy(() => import ("./components/register/SetupRestaurant"))
const AppKey = lazy(() => import ("./components/register/AppKey"))
const AuthSuccessfull = lazy(() => import ("./components/register/AuthSuccessfull"))
const GoogleBusinessCommunicationsAuthSuccessfull = lazy(() => import ("./components/register/GoogleBusinessCommunicationsAuthSuccessfull"))
const AuthSuccessfullCalendarApp = lazy(() => import ("./components/register/AuthSuccessfullCalendarApp"))
const Template = lazy(() => import ("./components/register/Template"))
const DataPrivacy = lazy(() => import ("./components/footer/DataPrivacy"))
const TermsOfService = lazy(() => import ("./components/footer/TermsOfService"))
const Hotels = lazy(() => import ("./components/carousel/Hotels"))
const Carousel = lazy(() => import ("./components/carousel/Carousel"))
const TicketConversation = lazy(() => import ("./components/TicketConversation"))
const TicketConversationSupport = lazy(() => import ("./components/TicketConversationSupport"))
const CreateSupportTicket = lazy(() => import ("./components/CreateSupportTicket"))
const SupportBoard = lazy(() => import ("./components/SupportBoard"))
const Bookings = lazy(() => import ("./components/Bookings"))
// const Dialogs = lazy(() => import ("./components/dialogs/Dialogs"))

const Testschedule2 = lazy(() => import ("./components/Testschedule2"))
const Testschedule3 = lazy(() => import ("./components/Testschedule3"))
const Restaurant = lazy(() => import ("./components/Restaurant"))
const BookATable = lazy(() => import ("./components/BookATable"))
const RestaurantTest = lazy(() => import ("./components/RestaurantTest"))
const Chats = lazy(() => import ("./components/Chats"))
const Rent = lazy(() => import ("./components/Rent"))
const SignUpPage = lazy(() => import ("./components/register/SignUpPage"))
const SignUpPageRestaurant = lazy(() => import ("./components/register/SignUpPageRestaurant"))
const SignUpPageFewo = lazy(() => import ("./components/register/SignUpPageFewo"))
const FacebookPages = lazy(() => import ("./components/FacebookPages"))
const CalendarAppWelcome = lazy(() => import ("./components/register/CalendarAppWelcome"))
const KleinanzeigenFav = lazy(() => import ("./components/KleinanzeigenFav"))
const Places = lazy(() => import ("./components/Maps"))
const SharePayment = lazy(() => import ("./components/SharePayment"))
const PageNotFound = lazy(() => import ("./components/PageNotFound"))
const AmazonCharts = lazy(() => import ("./components/AmazonCharts"))
const Inventory = lazy(() => import ("./components/Inventory"))


const AddToCalendar = lazy(() => import ("./components/AddToCalendar"))


//import AppSetup from "./components/setup/AppSetup"
//import Billing from "./components/Billing"
// import Settings from "./components/Settings"
// import Home from "./components/Home"
// import Docs from "./components/Docs"
// import Logs from "./components/Logs"
// import Support from "./components/Support"
// import { AuthProvider } from "./context/Context"

// import SignIn from "./components/SignIn"

// import AuthCalendar from "./components/register/AuthCalendar"
// import AppKey from "./components/register/AppKey"
// import Template from "./components/register/Template"






function App() {

  return (
    <div style={{overflow:"hidden",height:""}}>
      <Router>
        <Suspense fallback={SkeletonCalendar}>
				<Route path="/schedule" component={Schedule}/>
				<Route path="/reschedule" component={Reschedule}/>
				<Route path="/event" component={Event}/>
				<Route path="/carousel" component={Carousel}/>
				<Route path="/hotels" component={Hotels}/>
				<Route path="/addGaccountSuccess" component={AddGaccountSuccess}/>
				<Route path="/Places" component={Places}/>
				<Route path="/sharePayment" component={SharePayment}/>
			
		</Suspense>
			


        <Suspense fallback={<LoadingFallback />}>
			<Route path="/testschedule" component={RangePicker}/>	
			{/* <Route path="/testschedule" component={Testschedule}/>	 */}
			<Route path="/hotel" component={Testschedule}/>	
			<Route path="/testschedule2" component={Testschedule2}/>	
			<Route path="/testschedule3" component={Testschedule3}/>	
			<Route path="/calendarpicker" component={CalendarPickerDateOnly}/>	
			{/* <Route path="/beauty" component={Beauty}/>	 */}
			<Route path="/restaurant" component={Restaurant}/>	
			<Route path="/BookATable" component={BookATable}/>	
			<Route path="/restauranttest" component={RestaurantTest}/>	
			<Route path="/rent" component={Rent}/>	
			<Route path="/googleBusinessCommunications-success" component={GoogleBusinessCommunicationsAuthSuccessfull}/>
			<Route path="/addToCalendar" component={AddToCalendar}/>	


		</Suspense>

        
			
			<AuthProvider>
				<SidebarProvider>
					<Route path="/" exact component={LandingPage}/>
					<Route path="/ferienwohnung-buchen" component={LandingPageFewo}/>
					<Route path="/impressumFewo" component={FewoLegalPage}/>


					<Suspense fallback={<LoadingFallback />}>
						<Route path="/template" component={Template}/>
						<PrivateRoute path="/authcalendar" component={AuthCalendar}/>
						<Route path="/addgaccount" component={AuthCalendar}/>
						<Route path="/appkey" component={AppKey}/>
						<Route path="/auth-success" component={AuthSuccessfull}/>
						<Route path="/auth" exact component={SignInPage}/>
						<Route path="/signin" exact component={SignInPage}/>
						<Route path="/login" exact component={SignInPage}/>
						<Route path="/signup" component={SignUpPage}/>
						<Route path="/signUpPageFewo" component={SignUpPageFewo}/>
						<Route path="/signuprestaurant" component={SignUpPageRestaurant}/>
						<Route path="/terms-of-service" exact component={TermsOfService}/>
						<Route path="/data-privacy" exact component={DataPrivacy}/>
						{/* <Route path="/chats" exact component={Chats}/> */}
						<PrivateRoute path="/home" component={Home}/>
						<PrivateRoute path="/fbookings" component={Bookings}/>
						<PrivateRoute path="/billing" component={Billing}/>
						<PrivateRoute path="/setup" component={AppSetup}/>
						<PrivateRoute path="/settings" component={Settings}/>
						<PrivateRoute path="/logs" component={Logs}/>
						<PrivateRoute path="/events" component={Events}/>
						<PrivateRoute path="/docs" component={Docs}/>
						<PrivateRoute path="/support" component={Support}/>
						<PrivateRoute path="/newticket" component={CreateSupportTicket}/>
						<PrivateRoute path="/ticket" component={TicketConversation}/>
						<PrivateRoute path="/respondtoticket" component={TicketConversationSupport}/>
						<PrivateRoute path="/supportboard" component={SupportBoard}/>
						<PrivateRoute path="/chats" component={Chats}/>
						<PrivateRoute path="/facebookPages" component={FacebookPages}/>
						<PrivateRoute path="/calendarAppWelcome" component={CalendarAppWelcome}/>
						<PrivateRoute path="/KleinanzeigenFav" component={KleinanzeigenFav}/>
						<Route path="/setupRestaurant" component={SetupRestaurant}/>


						<Route path="/AmazonCharts" component={AmazonCharts}/>
						<Route path="/inventory" component={Inventory}/>

						{/* <Route component={SignUpPage}/> */}

					
					</Suspense>

				</SidebarProvider>

			</AuthProvider>

			{/* <Route path='*' exact={true} component={PageNotFound}/> */}

			{/* <Switch>
			</Switch> */}
			
      </Router>
      
    </div>
  );
}



export default App;

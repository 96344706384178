import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { IconButton, CircularProgress, InputBase, Badge } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachmentIcon from '@mui/icons-material/Attachment';
import MinimizeIcon from '@mui/icons-material/Minimize';
import { firestore } from './Firebase';
import './LiveChat.css';
import {firebase_} from "./Firebase"

const LiveChat = () => {
  const [chatOpen, setChatOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      supportAgent: true,
      message: "Hello. This is the live chat service of the CalendarApp. Great to have you here.",
      messageID: '1',
      photoURL: "https://storage.googleapis.com/calendarapp-data-bucket/Logo/logo192.png"
    },
    {
      supportAgent: true,
      message: "How can I help you?",
      messageID: '2',
      photoURL: "https://storage.googleapis.com/calendarapp-data-bucket/Logo/logo192.png"
    }
  ]);
  const [newMessage, setNewMessage] = useState('');
  const [ticketID, setTicketID] = useState(null);
  const [ticketNumber, setTicketNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(1);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    const storedTicketID = localStorage.getItem('ticketID');

    if (storedTicketID) {
      setTicketID(storedTicketID);

      firestore
        .collection("supportTicketConversations")
        .where("ticketID", "==", storedTicketID)
        .onSnapshot(snapshot => {
          const fetchedMessages = [];
          snapshot.forEach(doc => {
            fetchedMessages.push(...doc.data().messages);
          });
          setUnreadMessages(1);
          setMessages(fetchedMessages);
        });
    }
  }, [ticketID]);

  useEffect(() => {
    if (chatOpen) {
      scrollToBottom();
    }
  }, [chatOpen]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const sendMessage = async () => {
    try {
      if (newMessage.trim() === '') return;

      const message = {
        supportAgent: false,
        message: newMessage,
      };

      setMessages(prevMessages => [...prevMessages, message]);
      setNewMessage('');
      setLoading(true);

      return axios({
        method: "post",
        url: "/api/livechat/sendMessage",
        headers: {
          withCredentials: true,
          "authorization": "Bearer CalendarApp"
        },
        data: {
          ticketID,
          memberID: "memberID",
          userID: "userID",
          flowID: "cancelcallback",
          calendarAppEventID: "eventID",
          channel: "channel",
          messenger: "messenger",
          instagram: "instagram",
          whatsapp: "whatsapp",
          telegram: "telegram",
          reschedule: "reschedule",
          userAgent: navigator.userAgent,
          calendarAppBusinessID: "calendarAppBusinessID",
          newMessage: newMessage
        }
      })
        .then(response => {
          if (response.data.ticketID) {
            setTicketID(response.data.ticketID);
            localStorage.setItem('ticketID', response.data.ticketID);
          }
          setTicketNumber(response.data.ticketNumber);
          scrollToBottom();
        })
        .catch(err => {
          console.error('Error sending message:', err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error sending message:', error);
      setLoading(false);
    }
  };

  const handleToggleChat = () => {
    if (!chatOpen) {
      setUnreadMessages(0); // Clear unread messages when chat is opened
    }
    setChatOpen(!chatOpen);
  };

  const handleFileChange = (event) => {
	console.log(event.target.files[0])
	if(!!event.target.files[0]){
		if(event.target.files[0].size > 25 * 1024 * 1024){
			// setSnackbarMessage("Error: Maximum File Size 25MB")
			// setSeverityStatus("error")
			// setOpenSnackbar(true)
			return
		}
		// if (event.target.files[0].type !== 'image/jpeg' || event.target.files[0].type !== 'image/png' || event.target.files[0].type !== 'image/gif' || event.target.files[0].type !== 'video/mp4' || event.target.files[0].type !== 'video/webm' || event.target.files[0].type !== 'video/ogg') {
		// 	setSnackbarMessage("Error: File type not supported")
		// 	setSeverityStatus("error")
		// 	setOpenSnackbar(true)
		// 	return
		// }
		setFile(event.target.files[0]);
		// handleSubmit(event.target.files[0])
		// handleSubmit(event.target.files[0])
		handleUploadClick(event.target.files[0])
	}
    // setFile(event.target.files[0]);
    // setFile(event.target.files);
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileSize,setFileSize] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [error, setError] = useState(null);

  const handleUploadClick = (file) => {
	console.log("file",file)
	console.log("file.name",file.name)
	console.log("file.size",file.size)
	setFileName(file.name)
	setFileSize(file.size)

    const storageRef = firebase_.storage().ref(`tickets/${file.name}`);
    const uploadTask = storageRef.put(file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // progress function
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        // error function
        console.log(error);
      },
      () => {
        // complete function
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL);
		  if(!!ticketID){addToTicketConversation(file.name,file.size,downloadURL)}
        //   setUrl(downloadURL);
        });
      }
    );
  };

  const addToTicketConversation = (name,size,url) => {
	setShowProgress(true)
    // event.preventDefault();
	console.log("fileName",fileName,"fileSize",fileSize)
	axios({
		method:"post",
		url: "/api/liveChat/addFilesToSupportTicket",
		headers: {
			withCredentials:true
		},
		data:{
			fileName: name,
			fileSize: size,
			fileUrl: url,
			ticketNumber:ticketNumber,
		}
		})
		.then( res => {
		// setUploaded(true);
		})
		.catch((error) => {
			console.error('Error:', error);
		});
  };

  return (
    <div className="live-chat-container">
      <Badge
        badgeContent={unreadMessages}
        color="secondary"
        invisible={chatOpen}
        style={{
          top: "-50px",
          right: "5px"
        }}
      >
        <div className={`chat-button ${chatOpen ? 'chat-open' : ''}`} onClick={handleToggleChat}>
          <img src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/logo192.png" alt="Chat Icon" />
        </div>
      </Badge>

      {chatOpen && (
        <div className="chat-window">
          <div className="chat-header">
            <h3>CalendarApp Live Chat</h3>
            <IconButton
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
              onClick={handleToggleChat}
            >
              <MinimizeIcon />
            </IconButton>
          </div>
          <div className="chat-body">
            {messages.map((msg) => (
              <div key={msg.messageID} className={`chat-message ${msg.supportAgent ? 'support' : 'user'}`}>
                {msg.supportAgent ? (
                  <div className="support-message">
                    <img src={msg.photoURL || "https://storage.googleapis.com/calendarapp-data-bucket/Logo/logo192.png"} alt="Support Icon" className="support-icon" />
                    <span>{msg.message}</span>
                  </div>
                ) : (
                  <div className="user-message">
                    <span>{msg.message}</span>
                    <img src="https://deroche-moore.de/wp-content/uploads/2022/04/Unkown-contact.jpg" alt="User Icon" className="user-icon" height="24px" width="24px" style={{ borderRadius: "50%" }} />
                  </div>
                )}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input-container">
            {/* <IconButton color="primary" disabled={showProgress}>
			{showProgress ? 
			<CircularProgress 
				size={24}
			/> : 
			<div>
				<AttachmentIcon />
			</div>
			}
			
			<input hidden type="file" onChange={handleFileChange} accept="image/jpeg, image/png, image/gif, image/bmp, image/webp, image/tiff, image/svg+xml, video/mp4, video/ogg, video/webm, video/x-ms-wmv, video/x-flv, video/x-msvideo, video/quicktime, application/pdf" />
              
            </IconButton> */}
            <InputBase
              className="chat-input"
              placeholder="Schreibe eine Nachricht..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              disabled={loading}
              fullWidth
            />
            <IconButton color="primary" onClick={sendMessage} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : <SendIcon />}
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveChat;
